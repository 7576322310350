import { Button, Card, Checkbox, Flex, Text, TextField } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import codeCheckContainer from "../../containers/codeCheck.container"
import SurveyLogo from "../surveyLogo"
import CodeAlreadyUsed from "./codeAlreadyUsed"

const CodeCheckContent = ({
  individualCode,
  isValidCode,
  isAnonymous,
  codeAlreadyUsed,
  surveyId,
  surveyType,
  status,
  setCode,
  onNext,
  onPrevious
}) => {
  const [textCode, onTextCodeChange] = useState(individualCode)
  const [anonymous, setAnonymous] = useState(false)
  const [error, setError] = useState(false)
  const { t } = useTranslation(["organisation_survey"])

  useEffect(() => {
    if (isValidCode || status === "draft" || status === "scheduled") {
      onNext()
    }
  }, [isValidCode, onNext, status])

  if (codeAlreadyUsed && !anonymous) {
    return <CodeAlreadyUsed onNext={onNext} onPrevious={onPrevious} />
  }

  const handleSubmit = () => {
    if (anonymous) {
      onNext()
    }

    if (surveyType !== "organisation") {
      if (textCode.length > 2) {
        setCode(textCode)
        onNext()
      }
      setError(true)
    } else {
      checkCode({ code: textCode, surveyId })
    }
  }

  const handleClick = () => {
    setAnonymous(!anonymous)
    onTextCodeChange("")
  }

  return (
    <>
      <Card bg="light.0" mt={[5, 5, 6]} mb={[6, 5]} elevation={8} px={4}>
        <Flex flexDirection="column" py={3} px={[3, 4]} alignContent="center">
          <SurveyLogo />
          <Text color="dark.1" fontSize={4} textAlign="center" my={3} fontWeight={500}>
            {t("code_check.text1", "")}
          </Text>

          <TextField
            value={textCode || ""}
            style={{ fontWeight: 700 }}
            onChange={e => onTextCodeChange(e.target.value)}
            width={1 / 1}
            error={!anonymous && error && "Value must be at least 3 characters."}
            placeholder={t("code_check.enter_code", "")}
          />

          <Text color="dark.1" fontSize={4} fontWeight={500}>
            {t("code_check.text3", "")}
          </Text>
          {isAnonymous && (
            <>
              <Text color="dark.1" fontSize={4} fontWeight={500} mt={3}>
                {t("code_check.text4", "")}
              </Text>

              <Text color="dark.1" fontSize={4} fontWeight={500} mt={2} mb={2}>
                {t("code_check.text5", "")}
              </Text>

              <Checkbox
                colour="dark.1"
                ml={0}
                mt={2}
                id="sameBilling"
                checked={anonymous}
                onChange={handleClick}
                label={() => (
                  <Text color="dark.1" fontSize={4} fontWeight={500}>
                    {t("code_check.checkbox_text", "")}
                  </Text>
                )}
              />
            </>
          )}
        </Flex>
        <Flex flexDirection="row" py={2} px={[3, 4]} justifyContent="space-between">
          <Button variant="text.dark.1" elevation={0} onClick={onPrevious} my={2} mx={[3, 4]}>
            {t("code_check.cancel", "")}
          </Button>
          <Button my={2} variant="primary.0" onClick={handleSubmit}>
            {t("code_check.submit", "")}
          </Button>
        </Flex>
      </Card>
    </>
  )
}

export default codeCheckContainer(CodeCheckContent)

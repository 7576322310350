import React from "react"
import { useSelector } from "react-redux"

import {
  getCodeAlreadyUsed,
  getHasValidCode,
  getIndividualCode,
  getIsValidatingCode,
  getRequiresCode,
  getSurveyType
} from "../../store/survey.selectors"
import CodeAlreadyUsed from "./codeAlreadyUsed"
import CodeCheckContent from "./codeCheckContent"
import InvalidCode from "./invalidCode"
import ValidatingCode from "./validatingCode"

const CodeCheck = ({ onNext, onPrevious }) => {
  const requiresCode = useSelector(getRequiresCode)
  const codeAlreadyUsed = useSelector(getCodeAlreadyUsed)
  const isValidCode = useSelector(getHasValidCode)
  const code = useSelector(getIndividualCode)
  const isValidatingCode = useSelector(getIsValidatingCode)
  const isNSE = ["employeeStarter", "employeeExit"].includes(useSelector(getSurveyType))

  if (isValidatingCode) return <ValidatingCode />

  if (requiresCode && code && !isValidCode) return <InvalidCode onNext={onNext} onPrevious={onPrevious} />

  if (requiresCode && code && isValidCode && codeAlreadyUsed)
    return <CodeAlreadyUsed onNext={onNext} onPrevious={onPrevious} />

  return <>{(requiresCode || isNSE) && <CodeCheckContent onNext={onNext} onPrevious={onPrevious} />}</>
}

export default CodeCheck

import { getConfig } from "@engaging-tech/ssr-config"
import { Auth } from "aws-amplify"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { getProfile } from "../features/yourProfile/store/yourProfile.selectors"
import { showFreshchatWidget } from "../utils/freschat"

const useFreschatWidget = () => {
  const { fsNormalWidgetId, fsMentorWidgetId } = getConfig()

  const user = useSelector(getProfile)

  useEffect(() => {
    ;(async () => {
      const isSignedIn = !!(await Auth.currentSession().catch(() => false))

      if (isSignedIn && !user) {
        return
      }

      let widgetId = null
      let tags = ["workl"]

      if (user?.premium?.active) {
        widgetId = fsMentorWidgetId
        tags = ["workl", "workl-premium"]
      } else if (user) {
        widgetId = fsNormalWidgetId
      }

      if (!widgetId) {
        showFreshchatWidget()
      } else {
        showFreshchatWidget(widgetId, tags, user.id, user.firstName, user.lastName, user.email)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsMentorWidgetId, fsNormalWidgetId, user?.id])

  return null
}

export default useFreschatWidget

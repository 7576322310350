import { connect } from "react-redux"

import { checkCode, setCode } from "../store/survey.actions"
import {
  getCodeAlreadyUsed,
  getHasValidCode,
  getId,
  getIndividualCode,
  getIsAnonymous,
  getStatus,
  getSurveyType
} from "../store/survey.selectors"

const mapState = state => ({
  individualCode: getIndividualCode(state),
  isValidCode: getHasValidCode(state),
  codeAlreadyUsed: getCodeAlreadyUsed(state),
  surveyType: getSurveyType(state),
  isAnonymous: getIsAnonymous(state),
  surveyId: getId(state),
  status: getStatus(state)
})

const mapDispatch = dispatch => ({
  checkCode: payload => dispatch(checkCode(payload)),
  setCode: payload => dispatch(setCode(payload))
})

const codeCheckContainer = connect(mapState, mapDispatch)

export default codeCheckContainer

import { getConfig } from "@engaging-tech/ssr-config"

export const destroyFreshchatWidget = () => {
  if (typeof window !== "undefined") {
    window.fcWidget.destroy()
  }
}

let appliedWidgetConfig = ""

export const showFreshchatWidget = async (
  widgetUuid,
  tags = ["workl"],
  userId,
  firstName,
  lastName,
  email
) => {
  if (typeof window !== "undefined") {
    const config = getConfig()

    let widgetConfig = {
      token: config.fsKey,
      host: config.fsDomain
    }
    if (userId || firstName || lastName || widgetUuid) {
      widgetConfig = {
        ...widgetConfig,
        externalId: userId,
        firstName: firstName || "Guest",
        lastName,
        email,
        widgetUuid,
        tags
      }
    }

    if (appliedWidgetConfig === JSON.stringify(widgetConfig)) {
      return
    }

    appliedWidgetConfig = JSON.stringify(widgetConfig)

    if (window.fcWidget.isLoaded()) {
      window.fcWidget.destroy()

      window.fcWidget.on("widget:destroyed", () => {
        window.fcWidget.init(widgetConfig)
      })
    } else {
      window.fcWidget.init(widgetConfig)
    }
  }
}

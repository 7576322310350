/* eslint-disable react-hooks/exhaustive-deps */
import { CustomHooks, Flex, Spinner } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useBeforeunload } from "react-beforeunload"
import { Responsive, WidthProvider } from "react-grid-layout"

import "../../../../node_modules/react-grid-layout/css/styles.css"
import "../../../../node_modules/react-resizable/css/styles.css"
import PrimaryButton from "../../ui/components/PrimaryButton"
import AddAWidget from "../containers/AddAWidget"
import { desktopColumns, mobileColumns, rowHeight, tabletColumns } from "../lib/gridSetup"
import { findWidgetWithinDictonaryById } from "../widgets/helpers/widgetHelpers"
import widgetDictonary from "../widgets/lib/widgetDictonary"

const localLayout = React.createRef()

const Grid = ({
  widgetLayout,
  allWidgets,
  loadAll,
  localLayoutChange,
  postLayout,
  closeOptionBoxes,
  gridCustomisationFlag,
  updateGridCustomisationFlag
}) => {
  const forceUpdate = CustomHooks.useForceUpdate()
  const [currentBreakpoint, setCurrrentBreakpoint] = useState("desktop")
  const [disableFreshChat, enableFreshChat] = CustomHooks.useFreshChatDeactivate(true)

  // Hook that applies a event listener to browser to run function before tab close
  useBeforeunload(() => {
    postLayout()
  })

  // Sets widget layout locally, any changes made to layout will be stored locally. Posts local layout on component unmount
  useEffect(() => {
    loadAll()
    localLayout.current = widgetLayout.data
    forceUpdate()
    return () => {
      updateGridCustomisationFlag(false)
      postLayout()
    }
  }, [])

  // Update local layout if new layout is passed into component, then force re-render
  useEffect(() => {
    localLayout.current = widgetLayout.data

    forceUpdate()
  }, [widgetLayout, gridCustomisationFlag])

  useEffect(() => {
    if (gridCustomisationFlag) {
      disableFreshChat()
    } else {
      enableFreshChat()
    }
  }, [gridCustomisationFlag])

  const ResponsiveGridLayout = WidthProvider(Responsive)

  // Generates grid components containing widgets based on local layout
  const generateWidgets = () => {
    if (localLayout.current) {
      return localLayout.current.desktop
        ?.map(layoutObject => {
          const widget = findWidgetWithinDictonaryById(layoutObject.i)
          if (widget?.Component) {
            return (
              <Flex key={layoutObject.i} style={{ position: "relative" }}>
                <widget.Component id={widget.id} />
              </Flex>
            )
          }
          return null
        })
        .filter(widget => widget)
    }
    return <></>
  }

  // Checks if all optional widgets have been added by checking local layout against all available widgets, if so, do not show addAWidget
  const displayAddAWidget = () => {
    if (gridCustomisationFlag) {
      return false
    }
    const selectedWidgetIds = widgetLayout.data.desktop.map(widgetLayoutObject => widgetLayoutObject.i)

    const allWidgetIds = Object.keys(widgetDictonary)
      .map(widgetKey => widgetDictonary[widgetKey])
      .filter(widget => !widget.disabled && widget.removable)
      .map(widget => widget.id)

    if (selectedWidgetIds && allWidgetIds) {
      return !allWidgetIds.every(widgetKey => selectedWidgetIds.includes(widgetKey))
    }
    return true
  }

  const updateLayout = layout => {
    const newLayout = localLayout.current
    newLayout[currentBreakpoint] = layout
    localLayout.current = newLayout
    localLayoutChange(newLayout)
  }

  const displayGrid =
    !allWidgets.loading &&
    !widgetLayout.loading &&
    localLayout.current?.desktop?.length > 0 &&
    allWidgets.data?.length > 0

  return (
    <>
      {displayGrid ? (
        <>
          <PrimaryButton
            onClick={() => {
              if (gridCustomisationFlag) {
                postLayout()
              }
              updateGridCustomisationFlag(!gridCustomisationFlag)
            }}
            height={gridCustomisationFlag ? [35, 42] : 35}
            width={gridCustomisationFlag ? [160, 190] : 160}
            mb={["10px", 0]}
            style={
              gridCustomisationFlag
                ? {
                    position: "fixed",
                    bottom: "20px",
                    left: "calc(50vw - 80px)",
                    zIndex: 1000
                  }
                : {}
            }
          >
            {gridCustomisationFlag ? "Save Grid Layout" : "Edit Grid Layout"}
          </PrimaryButton>

          <ResponsiveGridLayout
            className="layout"
            layouts={localLayout.current}
            breakpoints={{ desktop: 1036, tablet: 680, mobile: 0 }}
            cols={{
              desktop: desktopColumns,
              tablet: tabletColumns,
              mobile: mobileColumns
            }}
            containerPadding={[0, 0]}
            style={{ width: "100%", marginTop: "10px" }}
            rowHeight={rowHeight}
            isResizable={gridCustomisationFlag}
            isDraggable={gridCustomisationFlag}
            onBreakpointChange={breakpoint => setCurrrentBreakpoint(breakpoint)}
            onDragStart={(layout, oldItem, newItem, placeholder, e) => {
              // If user clicks on remove widget button, do not close option boxes
              // This was done as sometimes widgets would not be removed
              if (e.target.id !== "widget-remove-button") {
                closeOptionBoxes()
              }
            }}
            onResizeStart={closeOptionBoxes}
            onResizeStop={layout => updateLayout(layout)}
            onDragStop={layout => updateLayout(layout)}
          >
            {generateWidgets()}
          </ResponsiveGridLayout>
          {displayAddAWidget() && <AddAWidget />}
        </>
      ) : (
        <Spinner color="primary.0" justifyContent="center" width={1 / 1} height={200} />
      )}
    </>
  )
}

export default Grid

import React from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"

import { businessProfilePageSelectors } from "../../features/business-profile"
import BusinessProfileView from "../../features/business-profile/views/business-profile-view"
import PageLayout from "../../layouts/PageLayout"

export default function BusinessInformation() {
  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)
  const dunnsData = useSelector(businessProfilePageSelectors.getDunnsData)
  const metaCompanyName = dunnsData?.companyName ? dunnsData.companyName : bipInfo?.company ? bipInfo.company : ""

  return (
    <PageLayout
      bg={bipInfo?.profile?.status === "PUBLISHED" ? "#006666" : "#F5F5F5"}
      alignItems="center"
      flexDirection="column"
      isPublic
    >
      <Helmet>
        <title>World's Happiest Workplaces Live List: {metaCompanyName}</title>
        <meta name="description" content={`Workplace happiness data for ${metaCompanyName} from employees. ` +
        "Learn about employee satisfaction in areas like reward, management, and well-being. Make an informed career decision."} />
      </Helmet>
      <BusinessProfileView />
    </PageLayout>
  )
}

import { ResultsMatrix as Chart } from "@engaging-tech/charts"
import { Accordion, Box, Button, Flex, Icon, Text } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const ButtonFlex = styled(Flex)`
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary[1]};
  }
`

const TextTranslation = {
  job: "text1",
  role: "text2",
  "mental-health": "text3",
  development: "text4",
  heard: "text5",
  "career-path": "text6",
  "business-materials": "text7",
  relationship: "text8",
  ranking: "text9"
}

const hide = ["job", "role"]
const textTranslation = (callToActionDescription, t) => {
  if (hide.includes(callToActionDescription)) return null
  const value = TextTranslation[callToActionDescription]
  if (!value) return callToActionDescription
  const translation = t(`call_to_action.${value}`, "")
  if (callToActionDescription === "mental-health")
    return [translation, <strong> {t("call_to_action.text10", "")}</strong>]
  return translation
}

const ButtonTranslation = {
  job: "button1",
  role: "button2",
  "mental-health": "button3",
  development: "button4",
  heard: "button5",
  "career-path": "button6",
  "business-materials": "button7",
  relationship: "button8",
  ranking: "button9"
}

const buttonTranslation = (buttonText, t) => {
  const value = ButtonTranslation[buttonText]
  if (!value) return buttonText
  return t(`call_to_action.${value}`, "")
}

const CallToActionBox = ({ callToActionDescription, t, buttonText, path }) => {
  const Description = textTranslation(callToActionDescription, t)
  if (!Description) {
    return null
  }
  return (
    <Flex flexDirection={["column", "row"]} justifyContent="space-between" alignItems="center">
      <Box width={[3 / 5, 3 / 5, 7 / 10]} p={3} bg="secondary.3" my={4}>
        <Text textAlign="center" fontSize={3} color="dark.2">
          {Description}
        </Text>
      </Box>
      <a href={path} target="_blank" rel="noopener noreferrer">
        <ButtonFlex
          bg="secondary.0"
          py={2}
          mr={[0, 5, 4]}
          minWidth="250px"
          justifyContent="center"
          borderRadius={200}
          elevation={1}
        >
          <Text fontSize={[2, 2, 3]} fontWeight={500} width="auto" color="dark.2" mx={[0, 0, 2]}>
            {buttonTranslation(buttonText, t)}
          </Text>
        </ButtonFlex>
      </a>
    </Flex>
  )
}

const ResultsMatrix = ({ matrixData, matrixInfo, showAction = true }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const { t } = useTranslation(["results"])
  if (!matrixData || typeof matrixData === "undefined") return null
  const {
    title,
    description,
    labels: { x, y },
    bands,
    values
  } = matrixData

  if (Object.keys(values).length === 0 || !matrixInfo) return null
  const { icon, callToActionDescription, buttonText, path } = matrixInfo
  return (
    <Accordion icon={icon} title={titleCaps(title)} description={description}>
      <Flex justifyContent="flex-end" alignItems="center" my={2}>
        <Button
          variant="text.primary.0"
          trailingIcon={!showOverlay ? "help" : null}
          color="primary.0"
          fontSize={2}
          elevation={0}
          onClick={() => setShowOverlay(!showOverlay)}
        >
          {showOverlay ? <Icon name="close" color="primary.0" /> : t("matrices.show_label", "")}
        </Button>
      </Flex>
      <Box width={1 / 1}>
        <Chart
          data={values}
          yAxisLabels={y}
          xAxisLabels={x}
          quadrantLabels={bands}
          overlay={showOverlay}
          youLabel={t("matrix_chart.you", "")}
          globalLabel={t("matrix_chart.global", "")}
          industryLabel={t("matrix_chart.industry", "")}
        />
        {showAction && (
          <CallToActionBox
            callToActionDescription={callToActionDescription}
            t={t}
            buttonText={buttonText}
            path={path}
          />
        )}
      </Box>
    </Accordion>
  )
}

export default ResultsMatrix

// Grid setup
export const tabletColumns = 15
export const desktopColumns = 20
export const mobileColumns = 7
export const rowHeight = 30
export const defaultWidgetHeight = 5

// Static widgets that are always present on widget grid (unable to resize/move)
export const staticWidgetsDefault = [
  {
    // achievingGoals
    mobile: {
      id: "003",
      h: 6,
      w: mobileColumns,
      x: 0,
      y: 7
    },
    tablet: {
      id: "003",
      h: defaultWidgetHeight,
      w: tabletColumns - 4,
      x: 0,
      y: 9
    },
    desktop: {
      id: "003",
      h: defaultWidgetHeight,
      w: desktopColumns - 5,
      x: 0,
      y: 0
    }
  },
  {
    // manageAlerts
    mobile: {
      id: "005",
      h: 4,
      w: mobileColumns,
      x: 0,
      y: 13
    },
    tablet: {
      id: "005",
      h: defaultWidgetHeight,
      w: 4,
      x: tabletColumns - 4,
      y: 9
    },
    desktop: {
      id: "005",
      h: defaultWidgetHeight,
      w: 5,
      x: desktopColumns - 5,
      y: 0
    }
  },
  {
    // profile
    mobile: {
      id: "006",
      h: 7,
      w: mobileColumns,
      x: 0,
      y: 0
    },
    tablet: {
      id: "006",
      h: 9,
      w: tabletColumns,
      x: 0,
      y: 0
    },
    desktop: {
      id: "006",
      h: 8,
      w: 4,
      x: 0,
      y: 5
    }
  },
  {
    // latestScore
    mobile: {
      id: "004",
      h: 8,
      w: mobileColumns,
      x: 0,
      y: 17
    },
    tablet: {
      id: "004",
      h: 10,
      w: tabletColumns,
      x: 5,
      y: 9
    },
    desktop: {
      id: "004",
      h: 8,
      w: desktopColumns - 4,
      x: desktopColumns - 14,
      y: defaultWidgetHeight
    }
  },
  {
    // savedContent
    mobile: {
      id: "002",
      h: 6,
      w: mobileColumns,
      x: 0,
      y: 31
    },
    tablet: {
      id: "002",
      h: 6,
      w: tabletColumns,
      x: 0,
      y: defaultWidgetHeight + 9 + 10
    },
    desktop: {
      id: "002",
      h: 6,
      w: desktopColumns,
      x: 0,
      y: defaultWidgetHeight + 9 + 8
    }
  }
]

export const staticWidgetsProfileHighlight = [
  {
    // achievingGoals
    mobile: {
      id: "003",
      h: 6,
      w: mobileColumns,
      x: 0,
      y: 7
    },
    tablet: {
      id: "003",
      h: defaultWidgetHeight,
      w: tabletColumns - 4,
      x: 0,
      y: 9
    },
    desktop: {
      id: "003",
      h: defaultWidgetHeight,
      w: desktopColumns - 5,
      x: 0,
      y: 0
    }
  },
  {
    // manageAlerts
    mobile: {
      id: "005",
      h: 4,
      w: mobileColumns,
      x: 0,
      y: 7 + 6
    },
    tablet: {
      id: "005",
      h: defaultWidgetHeight,
      w: 4,
      x: tabletColumns - 4,
      y: 9
    },
    desktop: {
      id: "005",
      h: defaultWidgetHeight,
      w: 5,
      x: desktopColumns - 5,
      y: 0
    }
  },
  {
    // profile
    mobile: {
      id: "006",
      h: 7,
      w: mobileColumns,
      x: 0,
      y: 0
    },
    tablet: {
      id: "006",
      h: 9,
      w: tabletColumns,
      x: 0,
      y: 0
    },
    desktop: {
      id: "006",
      h: 8,
      w: 4,
      x: 0,
      y: 5
    }
  },
  {
    // latestScore
    mobile: {
      id: "004",
      h: 8,
      w: mobileColumns,
      x: 0,
      y: 17
    },
    tablet: {
      id: "004",
      h: 10,
      w: tabletColumns,
      x: 5,
      y: 9
    },
    desktop: {
      id: "004",
      h: 8,
      w: desktopColumns - 4,
      x: desktopColumns - 14,
      y: 5
    }
  },
  {
    // savedContent
    mobile: {
      id: "002",
      h: 6,
      w: mobileColumns,
      x: 0,
      y: 31
    },
    tablet: {
      id: "002",
      h: 6,
      w: tabletColumns,
      x: 0,
      y: defaultWidgetHeight + 9 + 10
    },
    desktop: {
      id: "002",
      h: 6,
      w: desktopColumns,
      x: 0,
      y: defaultWidgetHeight + 9 + 8
    }
  }
]

import { gql } from "../../../lib/Apollo"

export const CHECKCODE = gql`
  query ($code: String!, $surveyId: ID!) {
    isSubmissionCodeValid(code: $code, surveyId: $surveyId)
  }
`

export const LOAD = gql`
  query ($id: ID!) {
    getSurvey(surveyId: $id) {
      type
      id
      title
      status
      features {
        headerLogo
      }
      introduction {
        type
        content
      }
      languages {
        code
        name
        nativeName
      }
      meta {
        isAnonymous
        brand {
          colour
          logo
        }
        private
        mode {
          shareableLinks
          uniqueSubmission
        }
        isolated
      }
      template {
        id
        branchingLogic {
          targetAnswers
          targetSection
          targetQuestion
          choice
          currentQuestion
        }

        questions {
          type
          id
          title
          required
          description
          icon
          ... on ScaleQuestion {
            isCore
            min
            max
          }
          ... on DropdownQuestion {
            label
            isCore
            values {
              id
              value
            }
          }
          ... on SmileyFaceQuestion {
            label
            values {
              id
              value
              smileyValue
            }
          }
          ... on RadioQuestion {
            isCore
            values {
              id
              value
            }
          }
          ... on ShortTextQuestion {
            isCore
            fields {
              label
              limit
              type
            }
          }
        }
        sections {
          name
          visible
          description {
            type
            content
          }
          questions
        }
      }
    }
  }
`

export const SUBMIT = gql`
  mutation (
    $id: ID!
    $templateId: ID!
    $answers: [SurveyAnswerInput]!
    $code: String
    $language: String
    $savantaId: ID
  ) {
    createSurveySubmission(
      surveyId: $id
      surveyTemplateId: $templateId
      answers: $answers
      code: $code
      language: $language
      savantaId: $savantaId
    )
  }
`

export const ATTACH_USER = gql`
  mutation ($id: ID!, $token: String) {
    attachUserToSurveySubmission(surveySubmissionId: $id, token: $token)
  }
`

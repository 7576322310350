import { Button, Flex, H4 } from "@engaging-tech/components"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import PercentageGraphic from "../../../ui/components/PercentageGraphic"
import Explanation from "./Explanation"

const StepResult = ({ heading, step, section, numQuestions }) => {
  const { t } = useTranslation(["results"])

  const [showExplanation, setShowExplanation] = useState(false)

  return (
    <Flex flexDirection="column" alignItems="center" width={[40 / 100, 5 / 16]} my={2} mx={[2, 0]}>
      <H4
        fontSize={4}
        fontWeight={500}
        textAlign="center"
        color="dark.2"
        width={1 / 1}
        css={`
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {heading}
      </H4>
      <PercentageGraphic score={step?.score} />
      <Button variant="primary.0" disabled={step && !step.score} onClick={() => setShowExplanation(true)}>
        {t("six_steps.explanation", "")}
      </Button>

      {showExplanation ? (
        <Explanation
          score={step && step.score}
          setVisibility={setShowExplanation}
          heading={heading}
          section={section}
          numQuestions={numQuestions}
        />
      ) : null}
    </Flex>
  )
}

export default StepResult
